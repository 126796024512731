import { Type, Transform } from 'class-transformer'
import { some } from 'lodash'
import { DateTime } from 'luxon'
import { InvoiceStatus } from 'types/types'

interface LastFinalizationError {
    code: string
    doc_url: string
    message: string
    param: string
    payment_method_type: string
    type: string
}

export default class Invoice {
    id!: number
    billing_account_id!: number
    stripe_id!: string
    customer!: string
    description!: string
    attempt_count!: number
    payment_intent_id!: string
    hosted_invoice_url!: string
    invoice_pdf!: string
    invoice_number!: string
    receipt_number!: string
    status!: InvoiceStatus
    last_finalization_error!: LastFinalizationError

    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    created_at!: DateTime | null

    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    finalized_at!: DateTime | null

    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    marked_uncollectible_at!: DateTime | null

    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    paid_at!: DateTime | null

    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    voided_at!: DateTime | null

    @Type(() => DateTime)
    @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
    deleted_at!: DateTime | null

    attempted!: Boolean

    charge_id!: string
    charge_error!: string
    charge_payment_method_id!: string

    amount_due!: number
    amount_paid!: number
    amount_remaining!: number

    // annotate billing account
    billing_name!: string
    billing_email!: string
    customer_id!: string
    practice_group_id!: number | null
    practice_group_name!: string | null
    invoice_cycle!: string
    invoice_autopay!: string

    // annotate late fees and invoice type
    late_fees_pending!: number
    late_fees_paid!: number
    late_fees_cancelled!: number
    is_late_fees!: boolean
    is_job_payments!: boolean

    created_at_label(tz: string = 'Europe/London', format: string = 'ccc, LLL dd, yyyy') {
        const days_ago = Math.abs(Math.round(this.created_at?.diffNow('days').days ?? 0))
        const created_at = this.created_at?.setZone(tz).toFormat(format) ?? 'N/A'
        return `${created_at} (${days_ago} days ago)`
    }

    get amount_label() {
        return `£${(this.amount_due / 100).toFixed(2)}`
    }

    get status_label() {
        if (this.status === 'paid') {
            return 'Paid'
        } else if (this.charge_error) {
            return `Payment Failed (${this.charge_error})`
        } else if (this.status === 'open' && this.attempted) {
            return `${this.status} (Payment Initiated)`
        }
        return this.status
    }

    get is_overdue() {
        return this.status === 'open' && (this.created_at?.diffNow('days').days ?? 0) < -1
    }

    get has_late_fees() {
        return this.is_job_payments && some([this.late_fees_pending, this.late_fees_paid, this.late_fees_cancelled])
    }

    get late_fees_label() {
        if (!this.has_late_fees) return 'N/A'
        const pending = (this.late_fees_pending / 100).toFixed(2)
        const paid = (this.late_fees_paid / 100).toFixed(2)
        return `Pending: £${pending} | Paid: £${paid}`
    }
}
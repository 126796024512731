import React from 'react'
import { Box, Button, MenuItem } from '@mui/material'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import FormField from 'components/FormField'
import DescriptionList from 'components/DescriptionList'
import { DateTime } from 'luxon'
import ReportSelectBox from './ReportSelectBox'
import update from 'immutability-helper'
import AutoCompleteSelectField from 'components/AutoCompleteSelectField'
import PracticeGroup from 'logic/PracticeGroup'
import { KeyStatistics, KeyStatisticsSearchForm } from 'types/interfaces'
import { METRO_AREAS } from 'types/constants'

const KeyStatisticsPage: React.FC = () => {
    const { api } = useAPI()
    const { showAlert } = useSnackBarAlert()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [stats, setStats] = React.useState<KeyStatistics | null>(null)
    const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])
    const [form, setForm] = React.useState<KeyStatisticsSearchForm>({
        practice_group_id: -1,
        start_at: DateTime.now().minus({ days: 90 }).toFormat('yyyy-MM-dd'),
        end_at: DateTime.now().toFormat('yyyy-MM-dd'),
    })

    const updateForm = React.useCallback((name: string, value: any) => {
        setForm(update(form, { [name]: { $set: value } }))
    }, [form])

    const fetchPracticeGroups = React.useCallback(async () => {
        setPracticeGroups(await api.listPracticeGroup())
    }, [api])

    const fetchStats = React.useCallback(async () => {
        try {
            setLoading(true)
            const data = await api.getKeyStatistics(form)
            setStats(data[0])
        } catch (e) {
            console.error(e)
            showAlert('error', 'Error loading statistics')
        } finally {
            setLoading(false)
        }
    }, [api, form, showAlert])

    React.useEffect(() => {
        fetchPracticeGroups()
    }, [fetchPracticeGroups])

    React.useEffect(() => {
        fetchStats()
    }, [])

    const formatPercentage = (value: number) => value ? `${value}%` : 'N/A'
    const formatMinutes = (minutes: number) => minutes ? `${minutes.toFixed(2)} minutes` : 'N/A'

    const statsData = React.useMemo(() => {
        if (!stats) return []
        
        return [
            { label: 'Number of Jobs (total)', value: stats.jobs_total },
            { label: 'Number of Jobs (weekdays)', value: stats.jobs_weekdays },
            { label: 'Number of Jobs (weekends)', value: stats.jobs_weekends },
            { label: 'Number of Filled Jobs (total)', value: stats.jobs_filled_total },
            { label: 'Number of Filled Jobs (weekdays)', value: stats.jobs_filled_weekdays },
            { label: 'Number of Filled Jobs (weekends)', value: stats.jobs_filled_weekends },
            { label: 'Fill Rate (total)', value: formatPercentage(stats.pct_jobs_filled_total) },
            { label: 'Fill Rate (weekdays)', value: formatPercentage(stats.pct_jobs_filled_weekdays) },
            { label: 'Fill Rate (weekends)', value: formatPercentage(stats.pct_jobs_filled_weekends) },
            { label: 'Median Time to Fill (total)', value: formatMinutes(stats.median_minutes_taken_to_fill_jobs_total) },
            { label: 'Median Time to Fill (weekdays)', value: formatMinutes(stats.median_minutes_taken_to_fill_jobs_weekdays) },
            { label: 'Median Time to Fill (weekends)', value: formatMinutes(stats.median_minutes_taken_to_fill_jobs_weekend) },
        ]
    }, [stats])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
            <Header text='Key Statistics'>
                <ReportSelectBox />
            </Header>
            
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                <FormField
                    type='date'
                    name='start_at'
                    label='Start Date'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => updateForm('start_at', e.target.value)}
                    value={form.start_at ?? ''}
                />
                <FormField
                    type='date'
                    name='end_at'
                    label='End Date'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => updateForm('end_at', e.target.value)}
                    value={form.end_at ?? ''}
                />
                <FormField
                    select
                    sx={{ width: 200 }}
                    name='practice_group'
                    label='Practice Group'
                    onChange={(e) => updateForm('practice_group_id', e.target.value)}
                    value={form.practice_group_id ?? ''}>
                    <MenuItem value='-1'>Independent</MenuItem>
                    {practiceGroups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                    ))}
                </FormField>
                <FormField
                    select
                    sx={{ width: 200 }}
                    name='metro_area'
                    label='Metro Area'
                    onChange={(e) => updateForm('metro_area', e.target.value)}
                    value={form.metro_area ?? ''}>
                    <MenuItem value=''>All Areas</MenuItem>
                    {METRO_AREAS.map((area) => (
                        <MenuItem key={area} value={area}>
                            {area.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                        </MenuItem>
                    ))}
                </FormField>
                <Button
                    variant="contained"
                    onClick={fetchStats}
                    disabled={loading}
                >
                    {loading ? 'Please Wait ...' : 'Search'}
                </Button>
            </Box>

            {stats && (
                <Box sx={{ maxWidth: '800px' }}>
                    <DescriptionList 
                        data={statsData}
                        labelWidth="60%"
                        valueWidth="40%"
                        rowGap={2}
                    />
                </Box>
            )}
        </Box>
    )
}

export default KeyStatisticsPage

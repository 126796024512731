import { Alert, Box, Button, InputAdornment, Typography } from '@mui/material'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import React from 'react'
import { PracticeRebate, PracticeRebateSearchForm } from 'types/interfaces'
import { 
  DataGridPro, 
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import ReportSelectBox from 'pages/AdminReports/ReportSelectBox'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import update from 'immutability-helper'
import FormField from 'components/FormField'
import PracticeGroup from 'logic/PracticeGroup'
import AutoCompleteSelectField from 'components/AutoCompleteSelectField'
import { DateTime, Duration } from 'luxon'
import { fromCentsToPounds } from 'logic/helpers'

const PracticeRebateReport: React.FC = () => {
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])
  const [form, setForm] = React.useState<PracticeRebateSearchForm>({
    invoice_year: DateTime.now().year,
    invoice_month: DateTime.now().month,
  })
  const [items, setItems] = React.useState<PracticeRebate[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [threshold, setThreshold] = React.useState<number>(30)
  const [rebatePct, setRebatePct] = React.useState<number>(10)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
  }, [form])

  const fetchPracticeGroups = React.useCallback(async (searchTerm: string) => {
    if (!searchTerm) return
    setPracticeGroups(await api.listPracticeGroup({ name: searchTerm }))
}, [api])

  const fetchReport = React.useCallback(async () => {
    try {
      setLoading(true)
      setItems(await api.listPracticeRebates(form))
    } catch (e) {
      console.error(e)
      showAlert('error', 'Error loading report')
    } finally {
      setLoading(false)
    }
  }, [api, form, showAlert])

  const calculateRebate = React.useCallback((item: PracticeRebate) => {
    return item.locumloop_fees * rebatePct / 100
  }, [rebatePct])

  const itemsWithinThreshold = React.useMemo(() => {
    const isWithinThreshold = (item: PracticeRebate) => {
      const duration = Duration.fromMillis(1000 * Number(item.max_payment_duration))
      return duration.as('days') <= threshold
    }
    return items.filter(isWithinThreshold)
  }, [items, threshold])

  const totalRebate = React.useMemo(() => {
    return itemsWithinThreshold.reduce((acc, item) => acc + calculateRebate(item), 0)
  }, [itemsWithinThreshold, calculateRebate])

  React.useEffect(() => {
    fetchReport()
  }, [])

  const columns: GridColDef<PracticeRebate>[] = React.useMemo(() => [{
    minWidth: 200,
    headerName: 'Billing Account ID',
    field: 'billing_account_id',
    type: 'number',
  }, {
    minWidth: 250,
    headerName: 'Billing Account Name',
    field: 'billing_account_name',
    type: 'string',
  }, {
    minWidth: 200,
    headerName: 'Number of Invoices',
    field: 'num_invoices',
    type: 'number',
  }, {
    minWidth: 200,
    headerName: 'Total Nurse Fees',
    field: 'nurse_fees',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) => `£${fromCentsToPounds(params.value)}`,
  }, {
    minWidth: 200,
    headerName: 'Total Locumloop Fees',
    field: 'locumloop_fees',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) => `£${fromCentsToPounds(params.value)}`,
  }, {
    minWidth: 200,
    headerName: 'Payment Duration',
    field: 'median_payment_duration',
    type: 'number',
    renderCell: (params: GridRenderCellParams<PracticeRebate>) => {
      const item = params.row
      const min = Duration.fromMillis(1000 * Number(item.min_payment_duration))
      const max = Duration.fromMillis(1000 * Number(item.max_payment_duration))
      const median = Duration.fromMillis(1000 * Number(item.median_payment_duration))
      if (min.equals(max)) {
        return `${min.toFormat('d')} days`
      } else {
        return `${min.toFormat('d')} - ${max.toFormat('d')} days (Median: ${median.toFormat('d')} days)`
      }
    }
  }, {
    minWidth: 200,
    headerName: 'Rebate',
    field: 'rebate',
    type: 'number',
    renderCell: (params: GridRenderCellParams<PracticeRebate>) => {
      const item = params.row
      return `£${fromCentsToPounds(calculateRebate(item))?.toFixed(2)}`
    }
  }], [calculateRebate])

  const practiceGroupSelectOptions = React.useMemo(() => {
    return practiceGroups.map((practiceGroup) => ({
      value: practiceGroup.id,
      label: practiceGroup.name,
    })).concat([{
        value: -1,
        label: 'Independent'
    }])
  }, [practiceGroups])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Header text='Practice Retention Report'>
        <ReportSelectBox />
      </Header>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <FormField
          type='number'
          name='invoice_year'
          label='Invoice Year'
          onChange={(e) => updateForm('invoice_year', e.target.value)}
          value={form.invoice_year ?? ''}/>
        <FormField
            type='number'
            name='invoice_month'
            label='Invoice Month'
            onChange={(e) => updateForm('invoice_month', e.target.value)}
            value={form.invoice_month ?? ''}
        />
        <AutoCompleteSelectField
            label='Select a practice group'
            sx={{ width: 250 }}
            options={practiceGroupSelectOptions}
            fetchOptions={fetchPracticeGroups}
            value={form.practice_group_id ?? ''}
            onChange={(value) => updateForm('practice_group_id', value)}
        />
        <Button
          variant="contained"
          onClick={fetchReport}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, border: 'solid thin black', padding: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          For every billing accounts paying their invoices within
          <FormField
            type='number'
            name='threshold'
            onChange={(e) => setThreshold(Number(e.target.value))}
            value={threshold}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  days
                </InputAdornment>
              ),
            }}
          />
          If we rebate 
          <FormField
            type='number'
            name='rebatePct'
            onChange={(e) => setRebatePct(Number(e.target.value))}
            value={rebatePct}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  %
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Typography>
          The total rebase amount will be £{fromCentsToPounds(totalRebate)?.toFixed(2)}
        </Typography>
      </Box>
      <Alert severity='info'>{itemsWithinThreshold.length} records returned</Alert>
      <DataGridPro 
        rows={itemsWithinThreshold}
        columns={columns}
        getRowId={(item) => item.billing_account_id}
        hideFooter
      />
    </Box>
  )
}

export default PracticeRebateReport
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import React from 'react'
import update from 'immutability-helper';
import { useNavigate } from 'react-router'
import { JobCancelForm } from 'types/interfaces'
import { Alert, Button, IconButton, MenuItem, Paper } from '@mui/material'
import Header from 'components/Header'
import { CancelOutlined } from '@mui/icons-material'
import FormField from 'components/FormField'
import ErrorList from 'components/ErrorList'
import JobCancelError from 'errors/JobCancelError';
import { useJob } from 'contexts/JobProvider';
import { practiceViewJobURL } from 'routes/urls';
import PracticeLateCancellationFeeLabel from 'components/PracticeLateCancellationFeeLabel';

type CancelReason = 'No nurse accepted the job' | 'Nurse no longer needed' | 'Other'

const PracticeCancelJob: React.FC = () => {
  const [form, setForm] = React.useState<JobCancelForm>({})
  const [error, setError] = React.useState<JobCancelError>()
  const { showAlert } = useSnackBarAlert()
  const { api } = useAPI()
  const navigate = useNavigate()
  const { job, reloadJob } = useJob()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [lateFees, setLateFees] = React.useState<number>(0)
  const [cancelReason, setCancelReason] = React.useState<CancelReason>('Nurse no longer needed')

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }, [error, form])

  const fetchLateFees = React.useCallback(async () => {
    if (!job) return
    setLateFees(await api.estimatePracticeLateCancellationFee(job.job_id))
  }, [job, api])

  const submit = React.useCallback(async () => {
    if (!job) return
    try {
      setLoading(true)
      await api.cancelJob(job.job_id, form)
      await reloadJob()
      showAlert('success', 'Job is cancelled.')
      navigate(practiceViewJobURL(job.practice_id, job.job_id))
    } catch (e: any) {
      if (e instanceof JobCancelError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }, [job, form, api, reloadJob, showAlert, navigate])

  const goBack = React.useCallback(() => {
    if (!job) return
    navigate(practiceViewJobURL(job.practice_id, job.job_id))
  }, [job, navigate])

  React.useEffect(() => {
    fetchLateFees()
  }, [fetchLateFees])

  React.useEffect(() => {
    if (cancelReason !== 'Other') {
      updateForm('reason', cancelReason)
    } else {
      updateForm('reason', '')
    }
  }, [cancelReason])

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1}}>
      <Header variant='h2' text='Cancel Job'>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <FormField
        select
        name='reason'
        label='Cancel Reason'
        onChange={(e) => setCancelReason(e.target.value as CancelReason)}
        value={cancelReason}
        errors={error?.reason}
      >
        <MenuItem value='No nurse accepted the job'>No nurse accepted the job</MenuItem>
        <MenuItem value='Nurse no longer needed'>Nurse no longer needed</MenuItem>
        <MenuItem value='Other'>Other</MenuItem>
      </FormField>
      {cancelReason === 'Other' ? (
        <FormField
          multiline
          rows={4}
          name='reason'
          label='Please provide more details'
          onChange={(e) => updateForm('reason', e.target.value)}
          value={form.reason}
          errors={error?.reason}
        />
      ) : null}
      {error?.schema ? (
        <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      { job && lateFees > 0 ? (
        <Alert severity='error' variant='filled'>
          <PracticeLateCancellationFeeLabel lateFees={lateFees} policy={job.practice_late_cancellation_policy} />
        </Alert>
      ) : null }
      <Button variant="contained" color="primary" onClick={submit} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Cancel Job'}
      </Button>
    </Paper>
  )
}

export default PracticeCancelJob
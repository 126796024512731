import { Type, Transform } from 'class-transformer'
import { DateTime, Duration } from 'luxon'
import { TimeSheetStatus } from 'types/types'

export default class TimeSheetChange {
  id!: number
  job_employment_id!: number

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  old_start_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  new_start_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  old_end_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  new_end_at!: DateTime

  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  old_lunch_break!: Duration

  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  new_lunch_break!: Duration

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  approved_at!: DateTime

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  disapproved_at!: DateTime

  created_by!: string
  approved_by!: string
  comments!: string
  status!: TimeSheetStatus
  differences!: string[]
  can_update!: boolean
  can_delete!: boolean
  can_approve!: boolean
  can_reject!: boolean

  nurse_tz: string = 'Europe/London'
  practice_tz: string = 'Europe/London'

  created_at_label(tz: string) {
    return this.created_at.setZone(tz).toFormat('ccc, LLL dd, yyyy, h:mm a')
  }

  get status_label() {
    switch (this.status) {
      case 'timesheet_approved':
        return 'Approved'
      case 'timesheet_disapproved':
        return 'Rejected'
      case 'timesheet_pending_nurse_approval':
      case 'timesheet_pending_practice_approval':
      default:
        return 'Pending'
    }
  }
}
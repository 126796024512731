import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import update from 'immutability-helper';
import FormField from 'components/FormField'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import { PracticeProfileForm, LoqateAddress } from 'types/interfaces'
import PracticeProfileError from 'errors/PracticeProfileError'
import AddressSearch from 'components/AddressSearch'
import ErrorPaper from 'components/ErrorAlert'
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { usePractice } from 'contexts/PracticeProvider';
import { useAuthUser } from 'contexts/AuthUserProvider';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { MenuItem } from '@mui/material';
import { MARKETING_CHANNELS } from 'types/constants';

const PracticeProfileEdit: React.FC = () => {
  const { authUser } = useAuthUser()
  const { practice, reloadPractice } = usePractice()
  const [form, setForm] = React.useState<PracticeProfileForm>({});
  const [error, setError] = React.useState<PracticeProfileError>();
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [loading, setLoading] = React.useState<boolean>(false)

  const updateForm = React.useCallback((name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }, [error, form])

  const prepopulateForm = React.useCallback(async () => {
    if (!practice) return
    setForm(update(form, {
      'name': { $set: practice.practice_name },
      'address': { $set: practice.address },
      'latitude': { $set: practice.latitude },
      'longitude': { $set: practice.longitude },
      'line1': { $set: practice.line1 },
      'line2': { $set: practice.line2 },
      'city': { $set: practice.city },
      'county': { $set: practice.county },
      'country': { $set: practice.country },
      'postcode': { $set: practice.postcode },
      'email_for_inquiries': { $set: practice.email_for_inquiries },
      'email_for_notifications': { $set: practice.email_for_notifications },
      'phone_for_inquiries': { $set: practice.phone_for_inquiries },
      'phone_for_notifications': { $set: practice.phone_for_notifications },
      'affiliate_code': { $set: practice.affiliate_code ?? '' },
      'marketing_channel': { $set: practice.marketing_channel ?? '' }
    }))
  }, [form, practice])

  const save = React.useCallback(async () => {
    if (!practice) return
    try {
      setLoading(true)
      await api.editPractice(form, practice.practice_id)
      await reloadPractice()
      showAlert('success', 'Practice Profile Updated')
    } catch (e: any) {
      if (e instanceof PracticeProfileError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }, [api, form, practice, reloadPractice, showAlert])

  React.useEffect(() => {
    prepopulateForm()
  }, [practice])

  if (isEmpty(form) || !practice) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h1' text='Update Practice Information'></Header>
      <FormField
        name='name'
        label='Practice Name'
        onChange={(e) => updateForm('name', e.target.value)}
        value={form.name}
        errors={error?.name}
      />
      <FormField
        name='phone_for_inquiries'
        label='Phone Number (General Inquiries)'
        onChange={(e) => updateForm('phone_for_inquiries', e.target.value)}
        value={form.phone_for_inquiries}
        errors={error?.phone_for_inquiries}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <FormField
        name='phone_for_notifications'
        label='Phone Number (to receive SMS notifications)'
        onChange={(e) => updateForm('phone_for_notifications', e.target.value)}
        value={form.phone_for_notifications}
        errors={error?.phone_for_notifications}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <FormField
        name='email_for_inquiries'
        label='Email (General Inquiries)'
        onChange={(e) => updateForm('email_for_inquiries', e.target.value)}
        value={form.email_for_inquiries}
        errors={error?.email_for_inquiries}
      />
      <FormField
        name='email_for_notifications'
        label='Email (to receive notifications)'
        onChange={(e) => updateForm('email_for_notifications', e.target.value)}
        value={form.email_for_notifications}
        errors={error?.email_for_notifications}
      />
      <AddressSearch
        label='Address'
        form={form}
        error={error}
        setForm={setForm} />
      { authUser?.isStaff ? (
        <FormField
          name='affiliate_code'
          label='Affiliate Code'
          onChange={(e) => updateForm('affiliate_code', e.target.value)}
          value={form.affiliate_code}
          errors={error?.affiliate_code}
          helperText="Enter 'none' to unset affiliate code. Don't change code if there are existing affiliated transactions, otherwise the records may mess up"
        />
      ) : null }
      <FormField
        select
        name='marketing_channel'
        label='How did you hear about Locumloop?'
        onChange={(e) => updateForm('marketing_channel', e.target.value)}
        value={form.marketing_channel ?? ''}
        errors={error?.marketing_channel}
      >
        <MenuItem value=''>Please select</MenuItem>
        {MARKETING_CHANNELS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </FormField>
      <ErrorPaper errors={error?.schema}></ErrorPaper>
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Save'}
      </Button>
    </Box>
  )
}

export default PracticeProfileEdit
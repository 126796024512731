import React from 'react'
import {
  Alert,
  Button,
  Box,
  MenuItem,
  Typography,
} from '@mui/material'
import { createUserWithEmailAndPassword } from "firebase/auth";
import update from 'immutability-helper';
import { instanceToPlain, plainToClass } from 'class-transformer'
import { useFirebase } from 'contexts/FirebaseProvider'
import { useAPI } from 'contexts/APIProvider'
import { LoqateAddress, PracticeSignupForm } from 'types/interfaces';
import Header from 'components/Header'
import FormField from 'components/FormField'
import CheckboxFormField from 'components/CheckboxFormField'
import AddressSearch from 'components/AddressSearch'
import { useAuthUser } from 'contexts/AuthUserProvider';
import PracticeSignupContext from './context'
import PracticeSignupError from 'errors/PracticeSignupError';
import UserFormError from 'errors/UserFormError';
import { trim } from 'lodash'
import ErrorList from 'components/ErrorList';
import { MARKETING_CHANNELS, practicePositions } from 'types/constants'
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';


const initialValues = {
  position: 'Principal',
  agreed_contracts: false
}

const PracticeSignupBasicInfo: React.FC = () => {
  const { toNextStep, emitGTMEvent } = React.useContext(PracticeSignupContext)
  const { auth } = useFirebase()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const { refreshUser } = useAuthUser()
  const [form, setForm] = React.useState<PracticeSignupForm>(initialValues);
  const [error, setError] = React.useState<PracticeSignupError>();
  const [loading, setLoading] = React.useState<boolean>(false)

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  async function save() {
    try {
      setLoading(true)

      if (form.email) {
        const group = await api.userDomainCheck(form.email)
        if (group) {
          showAlert('info', `We have a corporate account for ${group}. Email us at hello@locumloop.com to signup under the corporate account.`)
          return
        }
      }

      // check password mismatch
      if (form.password1 !== form.password2) {
        throw 'password_mismatch'
      }

      // Dry-Run to validate submitted Practice Data
      await api.createPractice(form, true)

      // Create user on firebase
      await createUserWithEmailAndPassword(auth, form.email || '', form.password1 || '')

      // Create practice on backend
      const result = await api.createPractice(form, false)

      // Update user name
      await api.updateUser(result.user_id, { 
        first_name: form.first_name, 
        last_name: form.last_name,
      })

      // Refresh authToken (because backend API might have set claims on firebase auth)
      // await auth.currentUser?.getIdToken(true)
      await refreshUser(true)

      // send event to GTM
      emitGTMEvent('practice-signup-basic-info')

      // account created. proceed with next signup step
      toNextStep()

    } catch (e: any) {
      if (e === 'password_mismatch') {
        setError(plainToClass(PracticeSignupError, {
          '_schema': [
            'Password Mismatch. Please retype your password'
          ]
        }))
      } else if (e instanceof PracticeSignupError) {
        setError(e)
      } else if (e instanceof UserFormError) {
        setError(plainToClass(PracticeSignupError, instanceToPlain(e)))
      } else if (e?.message?.startsWith('Firebase')) {
        let errorMessage = `${e.message} ${e.code}`
        if (e?.code === 'auth/email-already-in-use') {
          errorMessage = 'There is an existing account registered with this email address.'
        }
        setError(plainToClass(PracticeSignupError, {
          '_schema': [errorMessage]
        }))
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  const updatePhoneNumber = React.useCallback((value: string) => {
    setForm(update(form, {
      phone_for_inquiries: { $set: value },
      phone_for_notifications: { $set: value },
    }))
  }, [form])

  const updateEmail = React.useCallback((value: string) => {
    setForm(update(form, {
      email: { $set: value },
      email_for_inquiries: { $set: value },
      email_for_notifications: { $set: value },
    }))
  }, [form])

  // Set affiliate ID depending on signup URL
  React.useEffect(() => {
    if (params.affiliateCode) {
      console.log(`Set Sign-up Affiliate Code = ${params.affiliateCode}`)
      updateForm('affiliate_code', params.affiliateCode)
    }
  }, [params.affiliateCode])

  // Pre-populate signup form with data from squarespace site (if available)
  React.useEffect(() => {
    const practiceName = searchParams.get('practice_name') ?? ''
    const firstName = searchParams.get('first_name') ?? ''
    const lastName = searchParams.get('last_name') ?? ''
    const email = searchParams.get('email') ?? ''
    setForm((form) => update(form, {
      'email': { $set: email },
      'email_for_inquiries': { $set: email },
      'email_for_notifications': { $set: email },
      'name': { $set: practiceName },
      'first_name': { $set: firstName },
      'last_name': { $set: lastName },
    }))
  }, [searchParams])
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Basic Information'></Header>
      <FormField
        name='name'
        label='Practice Name'
        onChange={(e) => updateForm('name', e.target.value)}
        value={form.name ?? ''}
        errors={error?.name}
      />
      <AddressSearch
        label='Address'
        form={form}
        error={error}
        setForm={setForm} />
      <FormField
        name='phone_number'
        label='Phone Number'
        onChange={(e) => updatePhoneNumber(e.target.value)}
        value={form.phone_for_inquiries ?? ''}
        errors={error?.phone_for_inquiries}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <FormField
        select
        name='position'
        label='Position of Contact'
        onChange={(e) => updateForm('position', e.target.value)}
        value={form.position ?? 'Principal'}
        errors={error?.position}>
        {practicePositions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </FormField>
      <FormField
        name='first_name'
        label='First Name of Contact'
        onChange={(e) => updateForm('first_name', e.target.value)}
        value={form.first_name ?? ''}
        errors={error?.first_name}
      />
      <FormField
        name='last_name'
        label='Last Name of Contact'
        onChange={(e) => updateForm('last_name', e.target.value)}
        value={form.last_name ?? ''}
        errors={error?.last_name}
      />
      <FormField
        name='email'
        label='Email'
        onChange={(e) => updateEmail(trim(e.target.value))}
        value={form.email ?? ''}
        errors={error?.email}
      />
      <FormField
        name='password1'
        label='Password'
        type='password'
        onChange={(e) => updateForm('password1', e.target.value)}
        value={form.password1 ?? ''}
        errors={error?.password1}
      />
      <FormField
        name='password2'
        label='Retype Password'
        type='password'
        onChange={(e) => updateForm('password2', e.target.value)}
        value={form.password2 ?? ''}
        errors={error?.password2}
      />
      <FormField
        name='affiliate_code'
        label='Affiliate Code (Optional)'
        helperText="If you are referred to locumloop by someone, enter the referrer's affiliate code here, otherwise just leave this blank"
        onChange={(e) => updateForm('affiliate_code', e.target.value)}
        value={form.affiliate_code ?? ''}
        errors={error?.affiliate_code}
      />
      <FormField
        select
        name='marketing_channel'
        label='How did you hear about Locumloop?'
        onChange={(e) => updateForm('marketing_channel', e.target.value)}
        value={form.marketing_channel ?? ''}
        errors={error?.marketing_channel}
      >
        <MenuItem value=''>Please select</MenuItem>
        {MARKETING_CHANNELS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </FormField>
      <CheckboxFormField
        name='agreed_contracts'
        label={
          <Typography variant='body2'>
            Check here to accept <a href='/legal/locumloop_practice.pdf' target='_blank' rel='noopener noreferrer'>the contract with locumloop</a> and <a href='/legal/practice_nurse.pdf' target='_blank' rel='noopener noreferrer'>the contract with nurses</a>
          </Typography>
        }
        onChange={(e) => updateForm('agreed_contracts', e.target.checked)}
        checked={form.agreed_contracts}
        errors={error?.agreed_contracts}
      />
      {error?.schema ? (
        <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Next'}
      </Button>
    </Box>
  )
}

export default PracticeSignupBasicInfo
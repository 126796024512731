import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import FormField from 'components/FormField'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'

interface RefreshCalendarProps {
  open: boolean
  setOpen: (value: boolean) => void
}

const RefreshCalendar: React.FC<RefreshCalendarProps> = ({ open, setOpen }) => {
  const { nurse, fetchNurse } = React.useContext(AdminNurseDetailContext)
  
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const [year, setYear] = React.useState<number>(new Date().getFullYear())
  const [month, setMonth] = React.useState<number>(new Date().getMonth() + 1)
  const [loading, setLoading] = React.useState<boolean>(false)

  const submit = async () => {
    if (!nurse) return
    try {
      setLoading(true)
      await api.refreshNurseCalendar(nurse.nurse_id, year, month)
      await fetchNurse()
      setOpen(false)
      showAlert('success', "Nurse calendar refreshed successfully")
    } catch (e) {
      console.error(e)
      showAlert('error', 'Error refreshing nurse calendar')
    } finally {
      setLoading(false)
    }
  }

  
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Refresh Nurse Calendar</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <FormField 
          type='number'
          label='Year'
          value={year}
          onChange={(e) => setYear(Number(e.target.value))}
        />
        <FormField 
          type='number'
          label='Month'
          value={month}
          onChange={(e) => setMonth(Number(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={submit} disabled={loading}>
          { loading ? 'Please Wait ...' : 'Refresh Calendar' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RefreshCalendar
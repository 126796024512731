import { 
  Box, 
  Typography, 
  Tabs, 
  Tab,
  ButtonGroup,
  IconButton,
  Chip,
 } from '@mui/material'
 import {
  nurseJobsURL,
  nurseProfileURL,
  nurseBillingURL,
  nurseAchievementsURL,
  nurseNotificationSettings,
  nurseAffiliatePageURL,
  nurseDocumentsPageURL,
  adminUserUpdateURL,
} from 'routes/urls'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import PeopleIcon from '@mui/icons-material/People';
import BadgeIcon from '@mui/icons-material/Badge';
import { Outlet, Link as RouterLink, useMatch, useNavigate, useParams } from 'react-router-dom'
import AdminNurseDetailContext from 'pages/AdminNurseDetail/context'
import React from 'react'
import Nurse from 'logic/Nurse'
import { useAPI } from 'contexts/APIProvider'
import Loading from 'components/Loading'
import { BlacklistedNurseSearchForm, ChartSearchForm, JobEmploymentSearchForm, PracticeSearchForm } from 'types/interfaces';
import { GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid';
import update from 'immutability-helper'
import SplitButton from 'components/SplitButton';
import AccountSuspension from 'pages/AdminNurseDetail/AccountSuspension';
import ChargeLateFee from 'pages/AdminNurseDetail/ChargeLateFee';
import AccountUpdate from 'pages/AdminNurseDetail/AccountUpdate';
import RefreshCalendar from './RefreshCalendar';

const AdminNurseDetail: React.FC = () => {
  const { api } = useAPI()
  const params = useParams()
  const navigate = useNavigate()
  const [nurse, setNurse] = React.useState<Nurse>()
  const match = useMatch({ path: '/admin/nurses/:nurseId/:rest', caseSensitive: false, end: false })

  // Jobs Tab Context State
  const [jobSearchForm, setJobSearchForm] = React.useState<JobEmploymentSearchForm>({
    nurse_id: Number(params.nurseId)
  })
  const [jobSelection, setJobSelection] = React.useState<GridRowId[]>([])
  const [jobColumnVisibilityModel, setJobColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    nurse_name: false,
  })
  const [jobSortModel, setJobSortModel] = React.useState<GridSortModel>([])

  const updateJobSearchForm = React.useCallback((name: string, value: any) => {
    setJobSearchForm(update(jobSearchForm, { [name]: { $set: value } }))
  }, [jobSearchForm])

  // Reviews Tab Context State
  const [reviewsSearchForm, setReviewsSearchForm] = React.useState<JobEmploymentSearchForm>({
    nurse_id: Number(params.nurseId),
    is_rated: true,
  })
  const [reviewsSelection, setReviewsSelection] = React.useState<GridRowId[]>([])
  const [reviewsColumnVisibilityModel, setReviewsColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    nurse_name: false,
    cancel_at: false,
    cancel_by: false,
    cancel_reason: false,
    nurse_num_cancellations: false,
    practice_payment_method_available: false,
    nurse_id_issuing_country: false,
    billable_duration: false,
    payment_status: false,
  })
  const [reviewsSortModel, setReviewsSortModel] = React.useState<GridSortModel>([])

  const updateReviewsSearchForm = React.useCallback((name: string, value: any) => {
    setReviewsSearchForm(update(reviewsSearchForm, { [name]: { $set: value } }))
  }, [reviewsSearchForm])

  // Cancellations Tab Context State
  const [cancellationsSearchForm, setCancellationsSearchForm] = React.useState<JobEmploymentSearchForm>({
    nurse_id: Number(params.nurseId),
    is_cancelled: true,
  })
  const [cancellationsSelection, setCancellationsSelection] = React.useState<GridRowId[]>([])
  const [cancellationsColumnVisibilityModel, setCancellationsColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    nurse_name: false,
    review_rating: false,
    review_notes: false,
    practice_payment_method_available: false,
    nurse_id_issuing_country: false,
  })
  const [cancellationsSortModel, setCancellationsSortModel] = React.useState<GridSortModel>([])

  const updateCancellationsSearchForm = React.useCallback((name: string, value: any) => {
    setCancellationsSearchForm(update(cancellationsSearchForm, { [name]: { $set: value } }))
  }, [cancellationsSearchForm])

  // Blacklist Tab Context State
  const [blacklistsSearchForm, setBlacklistsSearchForm] = React.useState<BlacklistedNurseSearchForm>({
    nurse_id: Number(params.nurseId),
  })
  const [blacklistsSelection, setBlacklistsSelection] = React.useState<GridRowId[]>([])
  const [blacklistsColumnVisibilityModel, setBlacklistsColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [blacklistsSortModel, setBlacklistsSortModel] = React.useState<GridSortModel>([])

  const updateBlacklistsSearchForm = React.useCallback((name: string, value: any) => {
    setBlacklistsSearchForm(update(blacklistsSearchForm, { [name]: { $set: value } }))
  }, [blacklistsSearchForm])

  
  // Nearby Practice Tab Context State
  const [nearbyPracticeSearchForm, setNearbyPracticeSearchForm] = React.useState<PracticeSearchForm>({
    nurse_id: Number(params.nurseId)
  })
  const [nearbyPracticeSelection, setNearbyPracticeSelection] = React.useState<GridRowId[]>([])
  const [nearbyPracticeColumnVisibilityModel, setNearbyPracticeColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
  const [nearbyPracticeSortModel, setNearbyPracticeSortModel] = React.useState<GridSortModel>([])

  const updateNearbyPracticeSearchForm = React.useCallback((name: string, value: any) => {
    setNearbyPracticeSearchForm(update(nearbyPracticeSearchForm, { [name]: { $set: value } }))
  }, [nearbyPracticeSearchForm])

  // Charts Tab Context State
  const [chartsSearchForm, setChartsSearchForm] = React.useState<ChartSearchForm>({ 
    nurse_id: Number(params.nurseId)
  })

  const updateChartsSearchForm = React.useCallback((name: string, value: any) => {
    setChartsSearchForm(update(chartsSearchForm, { [name]: { $set: value } }))
  }, [chartsSearchForm])

  // Actions
  const [showAccountSuspension, setShowAccountSuspension] = React.useState<boolean>(false)
  const [showChargeLateFee, setShowChargeLateFee] = React.useState<boolean>(false)
  const [showAccountUpdate, setShowAccountUpdate] = React.useState<boolean>(false)
  const [showRefreshNurseCalendar, setShowRefreshNurseCalendar] = React.useState<boolean>(false)
  
  const fetchNurse = React.useCallback(async () => {
    setNurse(await api.getNurse(Number(params.nurseId)))
  }, [api, params.nurseId])

  const setEnableFlag = React.useCallback(async (enabled: boolean) => {
    if (!nurse) return
    const data = { nurse_ids: [nurse?.nurse_id], enabled: enabled }
    await api.enableNurses(data)
    await fetchNurse()
  }, [api, fetchNurse, nurse])

  // Init Page
  const selectedTab = React.useMemo(() => {
    return match?.params.rest ?? 'profile'
  }, [match?.params.rest])

  React.useEffect(() => {
    fetchNurse()
  }, [fetchNurse])

  if (!nurse) return <Loading />

  const contextValue = { 
    nurse,
    fetchNurse,
    // jobs
    jobSearchForm,
    jobSelection,
    jobColumnVisibilityModel,
    jobSortModel,
    updateJobSearchForm,
    setJobSelection,
    setJobColumnVisibilityModel,
    setJobSortModel,
    // reviews
    reviewsSearchForm,
    reviewsSelection,
    reviewsColumnVisibilityModel,
    reviewsSortModel,
    updateReviewsSearchForm,
    setReviewsSelection,
    setReviewsColumnVisibilityModel,
    setReviewsSortModel,
    // cancellations
    cancellationsSearchForm,
    cancellationsSelection,
    cancellationsColumnVisibilityModel,
    cancellationsSortModel,
    updateCancellationsSearchForm,
    setCancellationsSelection,
    setCancellationsColumnVisibilityModel,
    setCancellationsSortModel,
    // blacklisted
    blacklistsSearchForm,
    blacklistsSelection,
    blacklistsColumnVisibilityModel,
    blacklistsSortModel,
    updateBlacklistsSearchForm,
    setBlacklistsSelection,
    setBlacklistsColumnVisibilityModel,
    setBlacklistsSortModel,
    // nearby practices
    nearbyPracticeSearchForm,
    nearbyPracticeSelection,
    nearbyPracticeColumnVisibilityModel,
    nearbyPracticeSortModel,
    updateNearbyPracticeSearchForm,
    setNearbyPracticeSelection,
    setNearbyPracticeColumnVisibilityModel,
    setNearbyPracticeSortModel,
    // charts tab
    chartsSearchForm,
    updateChartsSearchForm,
  }
  return (
    <AdminNurseDetailContext.Provider value={contextValue}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center',  gap: 1 }}>
          <Typography variant='h1'>{ nurse?.full_name }</Typography>
          <ButtonGroup size='small'>
            <IconButton 
              component={RouterLink}
              to={nurseProfileURL(nurse.nurse_id)}
              target='_blank'
            >
              <AccountCircleIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={nurseJobsURL(nurse.nurse_id)}
              target='_blank'
            >
              <CalendarMonthIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={nurseDocumentsPageURL(nurse.nurse_id)}
              target='_blank'
            >
              <BadgeIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={nurseAchievementsURL(nurse.nurse_id)}
              target='_blank'
            >
              <EmojiEventsIcon />
            </IconButton>
            <IconButton 
              component={RouterLink}
              to={nurseBillingURL(nurse.nurse_id)}
              target='_blank'
            >
              <CurrencyPoundIcon />
            </IconButton>
            <IconButton
              component={RouterLink}
              to={nurseNotificationSettings(nurse.nurse_id)}
              target='_blank'
            >
              <EditNotificationsIcon />
            </IconButton>
            {nurse.is_affiliate ? (
              <IconButton
                component={RouterLink}
                to={nurseAffiliatePageURL(nurse.nurse_id, nurse.affiliate_id)}
                target='_blank'
              >
                <PeopleIcon />
              </IconButton>
            ) : null}
          </ButtonGroup>
          <Box sx={{ flexGrow: 1 }} />
          <SplitButton options={[{
            value: 'update-user',
            label: 'Update Email',
            onClick: () => navigate(adminUserUpdateURL(nurse.user_id))
          }, {
            value: 'enable-nurse',
            label: 'Enable Nurse',
            onClick: () => setEnableFlag(true)
          }, {
            value: 'disable-nurse',
            label: 'Disable Nurse',
            onClick: () => setEnableFlag(false)
          }, {
            value: 'account-suspension',
            label: 'Account Suspension',
            onClick: () => setShowAccountSuspension(true),
          }, {
            value: 'charge-late-fee',
            label: 'Charge Late Fee',
            onClick: () => setShowChargeLateFee(true)
          }, {
            value: 'set-red-dots',
            label: 'Set Red Dots',
            onClick: () => setShowAccountUpdate(true)
          }, {
            value: 'refresh-nurse-calendar',
            label: 'Refresh Nurse Calendar',
            onClick: () => setShowRefreshNurseCalendar(true)
          }]}/>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center',  gap: 0.5, borderBottom: 'solid thin black', paddingBottom: 1 }}>
          <Chip size='small' label={nurse.nurse_role} color='primary'/>
          {nurse.approved ? (
            <Chip size='small' label='Approved (Basic)' color='success' />
          ) : (
            <Chip size='small' label='Not Approved (Basic)' color='error' />
          )}
          {nurse.extended_compliance_approved ? (
            <Chip size='small' label='Approved (Extended)' color='success' />
          ) : (
            <Chip size='small' label='Not Approved (Extended)' color='error' />
          )}
          {nurse.scotland_compliance_approved ? (
            <Chip size='small' label='Approved (Scotland)' color='success' />
          ) : (
            <Chip size='small' label='Not Approved (Scotland)' color='error' />
          )}
          {nurse.hygienist_approved ? (
            <Chip size='small' label='Approved (Hygienist)' color='success' />
          ) : (
            <Chip size='small' label='Not Approved (Hygienist)' color='error' />
          )}
          {nurse.enabled ? (
            <Chip size='small' label='Enabled' color='success' />
          ) : (
            <Chip size='small' label='Disabled' color='error' />
          )}
          {nurse.is_suspended ? (
            <Chip size='small' label='Account Suspended' color='error' />
          ) : (
            null
          )}
          <Chip size='small' label={nurse.phone_number} />
          <Chip size='small' label={nurse.email} />
          <Chip size='small' label={`${nurse.nurse_tier} tier`} color={nurse.tier_color} />
        </Box>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto">
          <Tab label='Profile' value='profile' onClick={() => navigate('profile')}/>
          <Tab label='Documents' value='documents' onClick={() => navigate('documents')}/>
          <Tab label='Right to Work' value='rtw' onClick={() => navigate('rtw')}/>
          <Tab label='Stripe Status' value='stripe' onClick={() => navigate('stripe')}/>
          <Tab label='Jobs' value='jobs' onClick={() => navigate('jobs')}/>
          <Tab label='Reviews' value='reviews' onClick={() => navigate('reviews')}/>
          <Tab label='Cancellations' value='cancellations' onClick={() => navigate('cancellations')}/>
          <Tab label='Blacklist' value='blacklist' onClick={() => navigate('blacklist')}/>
          <Tab label='Nearby Practices' value='practices' onClick={() => navigate('practices')}/>
          <Tab label='Charts' value='charts' onClick={() => navigate('charts')}/>
        </Tabs>
        <Outlet />
        <AccountSuspension open={showAccountSuspension} setOpen={setShowAccountSuspension} />
        <ChargeLateFee open={showChargeLateFee} setOpen={setShowChargeLateFee} />
        <AccountUpdate open={showAccountUpdate} setOpen={setShowAccountUpdate} />
        <RefreshCalendar open={showRefreshNurseCalendar} setOpen={setShowRefreshNurseCalendar} />
      </Box>
    </AdminNurseDetailContext.Provider>
  )
}

export default AdminNurseDetail
import { Alert, Box, Button } from '@mui/material'
import AutoCompleteMultiSelectField from 'components/AutoCompleteMultiSelectField'
import ErrorList from 'components/ErrorList'
import FormField from 'components/FormField'
import Header from 'components/Header'
import { useAPI } from 'contexts/APIProvider'
import UserFormError from 'errors/UserFormError'
import { lowerCase, trim, uniqBy } from 'lodash'
import Practice from 'logic/Practice'
import PracticeGroup from 'logic/PracticeGroup'
import React from 'react'
import { UserForm } from 'types/interfaces'

interface AdminUserFormProps {
    title: string
    form: UserForm
    error?: UserFormError
    updateForm: (name: string, value: any) => void
    submit: () => Promise<void>
    loading: boolean
}

const AdminUserForm: React.FC<AdminUserFormProps> = ({ title, form, error, updateForm, submit, loading }) => {
    const { api } = useAPI()
    const [practices, setPractices] = React.useState<Practice[]>([])
    const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])

    const fetchPractices = React.useCallback(async (searchTerm: string = '') => {
        const practices = []
        if (form.practice_ids?.length ?? 0 > 0) {
            const results = await api.listPractices({ practice_ids: form.practice_ids })
            practices.push(...results)
        }
        if (searchTerm) {
            const results = await api.listPractices({ name: searchTerm })
            practices.push(...results)
        }
        setPractices(uniqBy(practices, 'practice_id'))
      }, [api, form.practice_ids])

    const fetchPracticeGroups = React.useCallback(async (searchTerm: string = '') => {
        const practiceGroups = []
        if (form.practice_group_ids?.length ?? 0 > 0) {
            const results = await api.listPracticeGroup({ practice_group_ids: form.practice_group_ids })
            practiceGroups.push(...results)
        }
        if (searchTerm) {
            const results = await api.listPracticeGroup({ name: searchTerm })
            practiceGroups.push(...results)
        }
        setPracticeGroups(uniqBy(practiceGroups, 'id'))
    }, [api, form.practice_group_ids])
    
    const practiceSelectOptions = React.useMemo(() => {
        return practices.map((practice) => ({
          value: practice.practice_id,
          label: practice.enabled ? practice.practice_name : `${practice.practice_name} (disabled)`,
        }))
    }, [practices])

    const practiceGroupSelectOptions = React.useMemo(() => {
        return practiceGroups.map((practiceGroup) => ({
            value: practiceGroup.id,
            label: practiceGroup.name,
        }))
    }, [practiceGroups])

    React.useEffect(() => {
        if (form.practice_ids) {
            fetchPractices()
        }
        if (form.practice_group_ids) {
            fetchPracticeGroups()
        }
    }, [form.practice_ids, form.practice_group_ids])
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Header variant='h1' text={title} />
            <FormField
                name='email'
                label='email'
                onChange={(e) => updateForm('email', trim(lowerCase(e.target.value)))}
                value={form.email ?? ''}
                errors={error?.email}
            />
            <FormField
                name='password'
                label='password'
                onChange={(e) => updateForm('password', e.target.value)}
                value={form.password ?? ''}
                errors={error?.password}
            />
            <FormField
                name='first_name'
                label='first_name'
                onChange={(e) => updateForm('first_name', e.target.value)}
                value={form.first_name ?? ''}
                errors={error?.first_name}
            />
            <FormField
                name='last_name'
                label='last_name'
                onChange={(e) => updateForm('last_name', e.target.value)}
                value={form.last_name ?? ''}
                errors={error?.last_name}
            />
            <AutoCompleteMultiSelectField
                label='Assign as manager to practices'
                options={practiceSelectOptions}
                fetchOptions={fetchPractices}
                values={form.practice_ids ?? []}
                errors={error?.practice_ids}
                onChange={(value) => updateForm('practice_ids', value)}
            />
            <AutoCompleteMultiSelectField
                label='Assign as master user to practice groups'
                options={practiceGroupSelectOptions}
                fetchOptions={fetchPracticeGroups}
                values={form.practice_group_ids ?? []}
                errors={error?.practice_group_ids}
                onChange={(value) => updateForm('practice_group_ids', value)}
            />
            {error?.schema ? (
                <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
            ) : null}
            <Button variant="contained" color="primary" onClick={submit} disabled={loading}>
                {loading ? 'Please Wait ...' : 'Save'}
            </Button>
        </Box>
    )
}

export default AdminUserForm

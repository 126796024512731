import { Alert, Box, Button, InputAdornment, MenuItem } from '@mui/material'
import AdditionalChargeFormError from 'errors/AdditionalChargeFormError'
import RefundFormError from 'errors/RefundFormError'
import React from 'react'
import update from 'immutability-helper'
import { AdditionalChargeForm, RefundForm } from 'types/interfaces'
import AdminEmploymentDetailContext from 'pages/AdminEmploymentDetail/context'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import Header from 'components/Header'
import FormField from 'components/FormField'
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import { fromCentsToPounds, fromPountsToCents } from 'logic/helpers'
import ErrorList from 'components/ErrorList'

type Action = 'refund' | 'additional_charge'

const FeeCorrectionForm: React.FC = () => {
    const { employment, fetchEmployment } = React.useContext(AdminEmploymentDetailContext)
    const { api } = useAPI()
    const { showAlert } = useSnackBarAlert()
    const [action, setAction] = React.useState<Action>('refund')
    const [refundForm, setRefundForm] = React.useState<RefundForm>({ employment_id: employment.id, locumloop_fees: 0 })
    const [refundError, setRefundError] = React.useState<RefundFormError>()
    const [additionalChargeForm, setAdditionalChargeForm] = React.useState<AdditionalChargeForm>({ employment_id: employment.id, locumloop_fees: 0 })
    const [additionalChargeError, setAdditionalChargeError] = React.useState<AdditionalChargeFormError>()
    const [loading, setLoading] = React.useState<boolean>(false)

    const updateForm = React.useCallback((name: string, value: any) => {
        if (action === 'refund') {
            setRefundForm(update(refundForm, { [name]: { $set: value } }))
            if (refundError) setRefundError(update(refundError, { [name]: { $set: [] } }))
        } else {
            setAdditionalChargeForm(update(additionalChargeForm, { [name]: { $set: value } }))
            if (additionalChargeError) setAdditionalChargeError(update(additionalChargeError, { [name]: { $set: [] } }))
        }
    }, [action, additionalChargeError, additionalChargeForm, refundError, refundForm])

    const form = React.useMemo(() => {
        if (action === 'refund') {
            return refundForm
        } else {
            return additionalChargeForm
        }
    }, [action, additionalChargeForm, refundForm])

    const error = React.useMemo(() => {
        if (action === 'refund') {
            return refundError
        } else {
            return additionalChargeError
        }
    }, [action, additionalChargeError, refundError])

    const submit = React.useCallback(async () => {
        setLoading(true)
        try {
            if (action === 'refund') {
                await api.createRefund(refundForm)
                showAlert('success', 'Refund successfully created')
            } else {
                await api.createAdditionalCharge(additionalChargeForm)
                showAlert('success', 'Additional Charge successfully created')
            }
            await fetchEmployment()
        } catch (e) {
            if (e instanceof RefundFormError) {
                setRefundError(e)
            } else if (e instanceof AdditionalChargeFormError) {
                setAdditionalChargeError(e)
            } else {
                console.error(e)
                showAlert('error', 'Error creating refund')
            }
        } finally {
            setLoading(false)
        }
    }, [action, additionalChargeForm, api, fetchEmployment, refundForm, showAlert])
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Header text='Fee Correction' />
            <FormField
                select
                value={action}
                onChange={(e) => setAction(e.target.value as Action)}
            >
                <MenuItem value={'refund'}>Refund</MenuItem>
                <MenuItem value={'additional_charge'}>Additional Charge</MenuItem>
            </FormField>
            <FormField
                name='nurse_fees'
                label='Nurse Fees'
                helperText={action === 'refund' ? 'Refund this amount from nurse to practice' : 'Charge practice this additional amount payable to nurse'}
                type='number'
                onChange={(e) => updateForm('nurse_fees', fromPountsToCents(e.target.value))}
                value={fromCentsToPounds(form.nurse_fees)}
                errors={error?.nurse_fees}
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <CurrencyPoundIcon />
                    </InputAdornment>
                ),
                }}
            />
            {/* TODO: backend is currently not refunding locumloop_fees on card payments correctly, but it works fine on invoice payments
            so we need to check if the payment option is an invoice payment before showing the refund locumloop_fees field */}
            {employment.pay_by_invoice ? (
                <FormField
                    name='locumloop_fees'
                    label='Locumloop Fees'
                    helperText={action === 'refund' ? 'Refund this amount from locumloop to practice' : 'Charge practice this additional amount payable to locumloop'}
                    type='number'
                    onChange={(e) => updateForm('locumloop_fees', fromPountsToCents(e.target.value))}
                    value={fromCentsToPounds(form.locumloop_fees)}
                    errors={error?.locumloop_fees}
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CurrencyPoundIcon />
                        </InputAdornment>
                    ),
                    }}
                />
            ) : null}
            <FormField
                name='description'
                label='Description'
                multiline
                minRows={4}
                onChange={(e) => updateForm('description', e.target.value)}
                value={form.description}
                errors={error?.description}
            />
            {error?.schema ? (
                <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
            ) : null}
            <Button
                variant="outlined"
                color="primary"
                onClick={submit}
                disabled={loading}>
                {loading ? 'Please Wait ...' : action === 'refund' ? 'Submit Refund' : 'Submit Additional Charge'}
            </Button>
        </Box>
    )
}

export default FeeCorrectionForm
import { Type, Transform, Expose } from 'class-transformer'
import { Duration, DateTime } from 'luxon'
import { fromKmToMiles, getFileTypeFromDocumentType } from 'logic/helpers'
import { chain, get } from 'lodash'
import {
  STEP_DOCUMENTS,
  STEP_BILLING,
  STEP_SUMMARY,
  STEP_IDCHECK,
  STRIPE_VERIFICATION_STATUS_MAPPING,
} from '../types/constants'
import {
  DocumentVerificationStatus,
  StripeStatus,
  DocumentType,
  IDVerificationStatus,
  RightToWorkStatus,
  NurseTier,
  NurseDocumentDateStatus,
  Role,
} from '../types/types'

export default class Nurse {
  nurse_id!: number
  firebase_id!: string
  user_id!: number

  @Type(() => DateTime)
  @Transform(({ value }) => DateTime.fromISO(value))
  created_at!: DateTime

  first_name!: string
  last_name!: string
  email!: string
  address!: string
  line1!: string
  line2!: string
  city!: string
  county!: string
  country!: string
  postcode!: string
  district!: string
  latitude!: number
  longitude!: number
  radius_km!: number
  tz!: string
  phone_number!: string

  // control flags
  enabled!: boolean
  agreed_contracts!: boolean
  
  // stripe connect setup
  stripe_user_id!: string
  payouts_enabled!: boolean
  details_submitted!: boolean
  requirements!: Record<string, any[]>
  
  // document data
  gdc_number!: string
  gdc_registration_name!: string
  gdc_registration_status!: string
  gdc_registrant_type!: string
  gdc_professional_title!: string
  gdc_registration_start_at!: string
  gdc_registration_end_at!: string
  gdc_reason!: string
  gdc_approved!: boolean | null
  indemnity_insurance_date!: string
  indemnity_insurance_reason!: string
  indemnity_insurance_approved!: boolean | null
  hepatitis_b_vaccination_date!: string
  hepatitis_b_vaccination_reason!: string
  hepatitis_b_vaccination_approved!: boolean | null
  hepatitis_c_date!: string
  hepatitis_c_reason!: string
  hepatitis_c_approved!: boolean | null
  tb_date!: string
  tb_reason!: string
  tb_approved!: boolean | null
  hiv_date!: string
  hiv_reason!: string
  hiv_approved!: boolean | null
  dbs_date!: string
  dbs_reason!: string
  dbs_approved!: boolean | null
  pvg_date!: string
  pvg_reason!: string
  pvg_approved!: boolean | null
  infection_control_date!: string
  infection_control_reason!: string
  infection_control_approved!: boolean | null
  cpr_date!: string
  cpr_reason!: string
  cpr_approved!: boolean | null
  resume_reason!: string
  resume_approved!: boolean | null
  
  // document file count
  gdc_file_count!: number
  indemnity_insurance_file_count!: number
  immunizations_file_count!: number
  dbs_file_count!: number
  infection_control_file_count!: number
  cpr_file_count!: number
  resume_file_count!: number

  // document status
  gdc_status!: DocumentVerificationStatus
  indemnity_insurance_status!: DocumentVerificationStatus
  hepatitis_b_vaccination_status!: DocumentVerificationStatus
  hepatitis_c_status!: DocumentVerificationStatus
  tb_status!: DocumentVerificationStatus
  hiv_status!: DocumentVerificationStatus
  dbs_status!: DocumentVerificationStatus
  pvg_status!: DocumentVerificationStatus
  infection_control_status!: DocumentVerificationStatus
  cpr_status!: DocumentVerificationStatus
  resume_status!: DocumentVerificationStatus

  // right to work
  rtw_status!: RightToWorkStatus
  rtw_sharecode!: string
  rtw_assumed!: boolean
  rtw_file!: string
  rtw_date!: string
  rtw_reason!: string
  rtw_approved!: boolean | null
  rtw_dob!: string
  
  // approval flags
  approved!: boolean
  extended_compliance_approved!: boolean
  scotland_compliance_approved!: boolean
  hygienist_approved!: boolean

  // prompt flags
  prompt_for_right_to_work !: boolean
  prompt_for_extended_compliance !: boolean
  
  // paygrade info
  @Type(() => Duration)
  @Transform(({ value }) => Duration.fromMillis(1000 * Number(value)))
  duration_worked!: Duration

  hours_worked!: number
  jobs_worked!: number
  
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  date_of_first_job!: DateTime | null

  paygrade_min_hours!: number
  paygrade_max_hours!: number
  paygrade_rate!: number  // in pounds, not cents.
  paygrade_next_rate!: number  // in pounds, not cents.
  num_cancellations!: number

  // ratings and performance
  ratings_excellent!: number
  ratings_good!: number
  ratings_average!: number
  ratings_poor!: number
  blacklist_count!: number
  job_count_last_period!: number
  job_count_overall!: number
  cancel_count_last_period!: number
  cancel_count_overall!: number

  // penalties
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  suspended_until!: DateTime | null
  penalty_note!: string
  require_late_cancellation_fee!: boolean

  // tiering
  nurse_num_matches!: number
  nurse_num_matches_with_penalty!: number
  nurse_pct_matches_with_penalty!: number
  nurse_tier!: NurseTier

  // notification settings
  notify_registration_prompts!: boolean
  notify_job_posts_via_email_sms!: boolean
  notify_job_posts_via_telegram!: boolean

  // affiliate info
  affiliate_id!: number
  affiliate_code!: string

  // API will only return these fields when listNurses with available_for_job_id
  earnings_estimate!: number  // in cents
  cancelled_employment_count!: number
  booked_employment_count!: number
  job_status!: 'hired' | 'cancelled' | 'not-yet-hired'
  
  // only available if listNurses with postcode/nearby_practice_id
  distance_km!: number | null

  // ID Verification
  verification_status!: IDVerificationStatus
  verification_error_code!: string
  verification_error_reason!: string
  verification_session_id!: string
  verification_report_id!: string
  verified_first_name!: string
  verified_last_name!: string
  id_document_type!: string
  id_issuing_country!: string

  // waitlist
  @Type(() => DateTime)
  @Transform(({ value }) => value ? DateTime.fromISO(value) : null)
  cpd_waitlisted_at!: DateTime | null

  // permission flags
  can_join_affiliate_programme!: boolean
  cannot_join_affiliate_programme_reason!: string

  // document date status
  gdc_date_status!: NurseDocumentDateStatus
  indemnity_insurance_date_status!: NurseDocumentDateStatus
  hepatitis_b_vaccination_date_status!: NurseDocumentDateStatus
  hepatitis_c_date_status!: NurseDocumentDateStatus
  tb_date_status!: NurseDocumentDateStatus
  hiv_date_status!: NurseDocumentDateStatus
  dbs_date_status!: NurseDocumentDateStatus
  pvg_date_status!: NurseDocumentDateStatus
  infection_control_date_status!: NurseDocumentDateStatus
  cpr_date_status!: NurseDocumentDateStatus
  resume_date_status!: NurseDocumentDateStatus

  // role
  nurse_role!: Role

  get full_name() {
    return `${this.first_name} ${this.last_name}`
  }

  get verified_full_name() {
    if (this.verification_status === 'verified') {
      return `${this.verified_first_name} ${this.verified_last_name}`
    }
    return ''
  }

  get tier_label() {
    const pct = this.nurse_pct_matches_with_penalty * 100
    return `${this.nurse_tier} tier - ${this.nurse_num_matches_with_penalty} out of ${this.nurse_num_matches} (${pct.toFixed(2)}%) matches with penalty`
  }

  get tier_color() {
    switch (this.nurse_tier) {
      case 'good':
        return 'success'
      case 'new':
        return 'warning'
      case 'bad':
        return 'error'
      default:
        return 'default'
    }
  }

  get is_affiliate() {
    return Boolean(this.affiliate_code)
  }

  get is_suspended() {
    return this.suspended_until ? this.suspended_until > DateTime.now() : false
  }

  get radius_miles() {
    return fromKmToMiles(this.radius_km)
  }

  get distance_miles() {
    return this.distance_km !== null ? fromKmToMiles(this.distance_km) : null
  }

  get location_label() {
    return `${this.city} (${this.district})`
  }

  get paygrade_label() {
    return `£${this.paygrade_rate}/hr`
  }

  get earnings_label() {
    return this.earnings_estimate ? `£${this.earnings_estimate / 100}`: "N/A"
  }

  get distance_label() {
    return this.distance_miles !== null ? `${this.distance_miles} miles` : 'N/A'
  }

  get travel_radius_label() {
    return `${this.radius_miles} miles`
  }

  get cancel_rate_last_period_label() {
    return `${this.cancel_count_last_period} (cancelled) / ${this.job_count_last_period} (total)`
  }

  get cancel_rate_overall_label() {
    return `${this.cancel_count_overall} (cancelled) / ${this.job_count_overall} (total)`
  }

  get cancel_percentage_label() {
    const pct = this.nurse_pct_matches_with_penalty * 100
    return `${pct.toFixed(2)}%`
  }

  get signup_at_label() {
    const countdown = (
      this.created_at.diffNow().negate()
      .shiftTo('days', 'hours', 'minutes')
      .toFormat("d 'days', h 'hrs', m 'mins'")
    )
    const dt = this.created_at.toFormat('ccc, LLL dd, yyyy')
    return `${countdown} ago (${dt})`
  }

  get signup_at_color() {
    return this.created_at.diffNow().as('hours') < -1 ? 'success' : 'error'
  }

  get first_job_label() {
    return this.date_of_first_job?.toFormat('ccc, LLL dd, yyyy') ?? 'N/A'
  }

  get first_job_color() {
    if (this.date_of_first_job) {
      return Math.abs(this.date_of_first_job.diffNow().as('days')) <= 1 ? 'success' : 'error'
    }
    return 'default'
  }

  cpd_waitlisted_label(tz: string) {
    return this.cpd_waitlisted_at?.setZone(tz).toFormat('ccc, LLL dd, yyyy, h:mm a') ?? 'N/A'
  }

  document_type_status_label(docType: DocumentType) {
    const status = get(this, `${docType}_status`)
    const fileType = getFileTypeFromDocumentType(docType)
    const file_count = get(this, `${fileType}_file_count`)
    return `${status}(${file_count})`
  }

  get pending_stripe_requirements() {
    return (
      chain(this.requirements)
        .get('currently_due', [])
        .map(x => get(STRIPE_VERIFICATION_STATUS_MAPPING, x, x))
        .value()
    )
  }

  get stripe_errors() {
    return (
      chain(this.requirements)
        .get('errors', [])
        .map(({ code, reason, requirement }) => `${requirement}: ${code}`)
        .value())
  }

  get stripe_step(): StripeStatus {
    if (this.payouts_enabled) {
      return 'COMPLETED'
    } else if (this.details_submitted) {
      return 'SUBMITTED'
    } else if (this.stripe_user_id !== '') {
      return 'PROCESSING'
    } else {
      return 'NOT_STARTED'
    }
  }

  get has_missing_documents() {
    return (
      chain([this.gdc_status, this.indemnity_insurance_status, this.hepatitis_b_vaccination_status])
      .map((status) => status === 'missing')
      .some()
      .value()
    ) || (
      chain([this.dbs_status, this.pvg_status])
      .map((status) => status === 'missing')
      .every()
      .value()
    )
  }

  get signup_step() {
    if (this.has_missing_documents) {
      return STEP_DOCUMENTS
    } else if (this.rtw_status === 'not-submitted') {
      return STEP_IDCHECK
    } else if (['NOT_STARTED'].includes(this.stripe_step)) {
      return STEP_BILLING
    } else {
      return STEP_SUMMARY
    }
  }

  document_status_color(documentStatus: DocumentVerificationStatus) {
    switch (documentStatus) {
      case 'disapproved':
        return 'error'
      case 'missing':
      case 'pending_verify':
        return 'warning'
      case 'approved':
        return 'success'
      default:
        return 'info'
    }
  }

  get stripe_status_color() {
    if (this.payouts_enabled) {
      return 'success'
    } else {
      return 'error'
    }
  }

  get rtw_status_color() {
    switch (this.rtw_status) {
      case 'approved':
        return 'success'
      case 'disapproved':
        return 'error'
      case 'pending-review':
        return 'warning'
      case 'not-submitted':
      default:
        return 'default'
    }
  }

  @Expose()
  get documents(): { name: string, status: NurseDocumentDateStatus }[] {
    const documentTypes = [
      'gdc',
      'indemnity_insurance',
      'hepatitis_b_vaccination',
      'hepatitis_c',
      'tb',
      'hiv',
      'dbs',
      'pvg',
      'infection_control',
      'cpr',
      'resume',
    ]
    return documentTypes.map(documentType => ({
      name: documentType,
      status: get(this, `${documentType}_date_status`),
    }))
  }

  @Expose()
  get documents_expired() {
    return (
      chain(this.documents)
      .filter((doc) => ['gdc', 'indemnity_insurance', 'cpr', 'infection_control'].includes(doc.name))  // only these documents have expiry dates
      .filter((doc) => doc.status === 'expired')
      .map((doc) => doc.name)
      .value()
    )
  }

  @Expose()
  get documents_missing_date() {
    return (
      chain(this.documents)
      .filter((doc) => ['gdc', 'indemnity_insurance', 'cpr', 'infection_control'].includes(doc.name))  // only these documents have expiry dates
      .filter((doc) => doc.status === 'date missing')
      .map((doc) => doc.name)
      .value()
    )
  }
}
import React from 'react'
import { usePractice } from 'contexts/PracticeProvider'
import { useNavigate, useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { practiceAddJobURL, practiceJobsURL, practiceViewJobURL } from 'routes/urls'
import { Box, Paper, Alert, Button, IconButton, Chip } from '@mui/material'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import Loading from 'components/Loading'
import { useAPI } from 'contexts/APIProvider'
import Job from 'logic/Job'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import { DateTime } from 'luxon'
import Header from 'components/Header'
import { isEmpty } from 'lodash'
import DescriptionList from 'components/DescriptionList'

const PracticeJobList: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const { practice } = usePractice()
  const [jobs, setJobs] = React.useState<Job[]>([])
  const [loading, setLoading] = React.useState(true)

  const date = React.useMemo(() => params.date ? DateTime.fromISO(params.date) : DateTime.now(), [params.date])

  const fetchJobs = React.useCallback(async () => {
    if (!practice) return
    try {
        setJobs(await api.listJobs({ 
          practice_id: practice.practice_id, 
          from_date: date.toFormat('yyyy-LL-dd'), 
          to_date: date.plus({ days: 1 }).toFormat('yyyy-LL-dd') 
        }))
    } catch (error) {
        showAlert('error', 'Failed to fetch jobs')
        console.error(error)
    } finally {
        setLoading(false)
    }
  }, [api, date, practice, showAlert])

  React.useEffect(() => {
    fetchJobs()
  }, [fetchJobs])

  function goBack() {
    if (practice) navigate(practiceJobsURL(practice.practice_id))
  }

  function goToJob(job: Job) {
    if (practice) navigate(practiceViewJobURL(practice.practice_id, job.job_id))
  }

  if (!practice || loading) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h2' text={date.toFormat('ccc, LLL dd, yyyy')}>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      {isEmpty(jobs) ? (
        <Alert severity='info'>
          No jobs posted on this date yet.
        </Alert>
      ) : (
        jobs.map(job => (
          <Paper key={job.job_id} onClick={() => goToJob(job)} sx={{ cursor: 'pointer', padding: 1 }}>
            <DescriptionList labelWidth='40%' valueWidth='60%' data={[{
              label: 'Time',
              value: job.time_label(practice.tz)
            }, {
              label: 'Lunch Break',
              value: job.lunch_break_label,
            }, {
              label: 'Number of Nurses Required',
              value: job.headcount.toString()
            }, {
              label: 'Role',
              value: job.job_role
            }, {
              label: 'Status',
              value: (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1}}>
                  <Chip
                    size='small'
                    label={job.fulfillment_status_label}
                    color={job.fulfillment_status_color} />
                  {job.payment_status ? (
                    <Chip
                      size='small'
                      label={job.payment_status_label}
                      color={job.payment_status_color} />
                  ) : null}
                  {job.timesheet_status ? (
                    <Chip
                      size='small'
                      label={job.timesheet_status_label}
                      color={job.timesheet_status_color} />
                  ) : null}
                </Box>
              )
            }]}></DescriptionList>
          </Paper>
        ))
      )}
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to={practiceAddJobURL(practice.practice_id, date.toISO())}>
        Add Another Job
      </Button>
    </Box >
  )
}

export default PracticeJobList
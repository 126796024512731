import React from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import update from 'immutability-helper';
import { useAPI } from 'contexts/APIProvider'
import { LoqateAddress, PracticeProfileForm } from 'types/interfaces';
import Header from 'components/Header'
import FormField from 'components/FormField'
import AddressSearch from 'components/AddressSearch'
import ErrorList from 'components/ErrorList'
import Loading from 'components/Loading'
import { isEmpty } from 'lodash';
import PracticeSignupContext from './context';
import PracticeProfileError from 'errors/PracticeProfileError';
import { MenuItem } from '@mui/material';
import { MARKETING_CHANNELS } from 'types/constants';

const PracticeSignupBasicInfoEdit: React.FC = () => {
  const { toNextStep, practice } = React.useContext(PracticeSignupContext)
  const { api } = useAPI()
  const [form, setForm] = React.useState<PracticeProfileForm>({});
  const [error, setError] = React.useState<PracticeProfileError>();
  const [loading, setLoading] = React.useState<boolean>(false)

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  const updatePhoneNumber = React.useCallback((value: string) => {
    setForm(update(form, {
      phone_for_inquiries: { $set: value },
      phone_for_notifications: { $set: value },
    }))
  }, [form])

  async function save() {
    if (!practice) return
    try {
      setLoading(true)
      await api.editPractice(form, practice.practice_id)
      toNextStep()
    } catch (e: any) {
      if (e instanceof PracticeProfileError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (!practice) return
    setForm((form) => update(form, {
      'name': { $set: practice.practice_name },
      'address': { $set: practice.address },
      'latitude': { $set: practice.latitude },
      'longitude': { $set: practice.longitude },
      'line1': { $set: practice.line1 },
      'line2': { $set: practice.line2 },
      'city': { $set: practice.city },
      'county': { $set: practice.county },
      'country': { $set: practice.country },
      'postcode': { $set: practice.postcode },
      'email_for_inquiries': { $set: practice.email_for_inquiries },
      'email_for_notifications': { $set: practice.email_for_notifications },
      'phone_for_inquiries': { $set: practice.phone_for_inquiries },
      'phone_for_notifications': { $set: practice.phone_for_notifications },
      'marketing_channel': { $set: practice.marketing_channel },
    }))
  }, [practice])

  if (isEmpty(form)) return <Loading />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      <Header variant='h3' text='Basic Information'></Header>
      <FormField
        name='name'
        label='Practice Name'
        onChange={(e) => updateForm('name', e.target.value)}
        value={form.name}
        errors={error?.name}
      />
      <FormField
        name='phone_number'
        label='Phone Number'
        onChange={(e) => updatePhoneNumber(e.target.value)}
        value={form.phone_for_inquiries}
        errors={error?.phone_for_inquiries}
        helperText='format: (+44)1234512345 or 01234512345)'
      />
      <AddressSearch
        label='Address'
        form={form}
        error={error}
        setForm={setForm} />
      <FormField
        select
        name='marketing_channel'
        label='How did you hear about Locumloop?'
        onChange={(e) => updateForm('marketing_channel', e.target.value)}
        value={form.marketing_channel ?? ''}
        errors={error?.marketing_channel}
      >
        <MenuItem value=''>Please select</MenuItem>
        {MARKETING_CHANNELS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </FormField>
      {error?.schema ? (
        <Alert severity='error'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait' : 'Next'}
      </Button>
    </Box>
  )
}

export default PracticeSignupBasicInfoEdit